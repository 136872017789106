import { createElement, FC } from 'react';
import {
  ButtonBaseProps,
  ButtonVariant,
  ButtonVariantProps
} from 'legos/button/base-button';
import {
  ButtonFlat as RawButtonFlat,
  ButtonFlatCCM
} from './button-flat.ccm.css';
import { useButtonTheme } from './button-flat.theme';

export const ButtonFlat: FC<ButtonBaseProps> = ({
  color = 'default',
  ...props
}) => {
  const theme = useButtonTheme();
  const ButtonComponent: FC<ButtonVariantProps<ButtonFlatCCM>> = ButtonVariant;
  return (
    <ButtonComponent
      variant={RawButtonFlat}
      $borderRadius={theme[color].borderRadius}
      $backgroundTransparent={theme[color].backgroundTransparent}
      $backgroundActive={theme[color].backgroundActive}
      $backgroundDisabledFocus={theme[color].backgroundDisabledFocus}
      $backgroundFocus={theme[color].backgroundFocus}
      $text={theme[color].text}
      $textDisabled={theme[color].textDisabled}
      {...props}
    />
  );
};
