import { createUseThemeHook, LegoColorPalette, LegoTheme } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    buttonFlatVariant: ButtonTheme;
  }
}

export interface ButtonVariant {
  backgroundActive: string;
  backgroundDisabledFocus: string;
  backgroundFocus: string;
  text: string;
  textDisabled: string;
  borderRadius: string;
  backgroundTransparent: string;
}

export interface ButtonTheme {
  default: ButtonVariant;
  accent: ButtonVariant;
  positive: ButtonVariant;
  negative: ButtonVariant;
  special: ButtonVariant;
  /** Sets the border radius for raised, flat and ghost buttons */
  borderRadius: string;
  backgroundTransparent: string;
}

const mapColorForButton = (
  global: LegoTheme,
  color: LegoColorPalette
): ButtonVariant => ({
  backgroundActive: color.color200,
  backgroundDisabledFocus: global.neutral.color100,
  backgroundFocus: global.neutral.color200,
  text: color.color500,
  textDisabled: global.neutral.color800,
  borderRadius: '2px',
  backgroundTransparent: 'transparent'
});

const [ButtonThemeProvider, useButtonTheme] = createUseThemeHook(
  'buttonFlatVariant',
  (global): ButtonTheme => ({
    default: mapColorForButton(global, global.default),
    accent: mapColorForButton(global, global.accent),
    positive: mapColorForButton(global, global.positive),
    negative: mapColorForButton(global, global.negative),
    special: mapColorForButton(global, global.special),
    borderRadius: '2px',
    backgroundTransparent: 'transparent'
  })
);

export { ButtonThemeProvider, useButtonTheme };
