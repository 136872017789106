
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.ButtonFlat_5a56cd2194cf0151{display:inline-flex;justify-content:center;align-items:center;padding:0 16px;margin:16px 0;min-width:96px;box-sizing:border-box;height:36px;border-radius:var(--borderRadius_5779127529929b9e);background-color:var(--backgroundTransparent_453ac61976498dde);color:var(--text_006dbe46c759475d);border:none;line-height:32px}.ButtonFlat_5a56cd2194cf0151:focus,.ButtonFlat_5a56cd2194cf0151:hover{background-color:var(--backgroundFocus_009f22cb57bb91a4)}.ButtonFlat_5a56cd2194cf0151:active{background-color:var(--backgroundActive_e48a30e3e44a7799)}.ButtonFlat_5a56cd2194cf0151.disabled_db4ffbc9629d27c9{background-color:transparent;color:var(--textDisabled_7506575ee634979b)}.ButtonFlat_5a56cd2194cf0151.disabled_db4ffbc9629d27c9:active,.ButtonFlat_5a56cd2194cf0151.disabled_db4ffbc9629d27c9:focus,.ButtonFlat_5a56cd2194cf0151.disabled_db4ffbc9629d27c9:hover{background-color:var(--backgroundDisabledFocus_10e6a3160e88a8ba)}.ButtonFlat_5a56cd2194cf0151.fullWidth_4b1af45470b50f2c{width:100%}`;
styleInject(_css)

/** ButtonFlat Props */
export type ButtonFlatCCM = {
  /** ButtonFlat Component Custom Properties */
  '$borderRadius': string;
  '$backgroundTransparent': string;
  '$text': string;
  '$backgroundFocus': string;
  '$backgroundActive': string;
  '$textDisabled': string;
  '$backgroundDisabledFocus': string;

  /** ButtonFlat Modifier Flags */
  '$disabled'?: boolean;
  '$fullWidth'?: boolean;
};
/** Base ButtonFlat component */
export const ButtonFlat: ComponentCreator<ButtonFlatCCM> = createComponentCreator({
  "name": "ButtonFlat",
  "base": "ButtonFlat_5a56cd2194cf0151",
  "prop": {
    "$borderRadius": "--borderRadius_5779127529929b9e",
    "$backgroundTransparent": "--backgroundTransparent_453ac61976498dde",
    "$text": "--text_006dbe46c759475d",
    "$backgroundFocus": "--backgroundFocus_009f22cb57bb91a4",
    "$backgroundActive": "--backgroundActive_e48a30e3e44a7799",
    "$textDisabled": "--textDisabled_7506575ee634979b",
    "$backgroundDisabledFocus": "--backgroundDisabledFocus_10e6a3160e88a8ba"
  },
  "mod": {
    "$disabled": "disabled_db4ffbc9629d27c9",
    "$fullWidth": "fullWidth_4b1af45470b50f2c"
  }
});

